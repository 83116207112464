import PropTypes from 'prop-types'
import React from 'react'
import tw, { css, theme } from 'twin.macro'
import { global } from '../styles/global'
import { remToPx } from '../styles/typography'
import { format } from '../utils/date'
import Button from './button'
import Heading from './heading'
import Link from './link'
import Text from './text'

const ExternalNews = ({ news, title }) => {
  return (
    <div css={[global`layout.container`, tw`flex flex-col`]}>
      <Heading
        headingType="h1"
        content={title}
        style={[
          global`typography.fluid`(
            remToPx(theme`fontSize.mobile-5xl`),
            remToPx(theme`fontSize.5xl`)
          ),
          tw`text-primary-500 text-opacity-40 lg:(leading-h2 tracking-h2)`,
        ]}
      />
      {news && (
        <div
          css={tw`grid grid-cols-1 gap-y-14 first-of-type:mt-12 lg:(grid-cols-3 mt-12 gap-x-12 gap-y-24)`}
        >
          {news.map((content) => (
            <Link key={content.headline} to={content.callToAction.link}>
              {content.image && (
                <div
                  css={css`
                    ${tw`mb-6 w-full relative`}
                    padding-top: 100%;
                  `}
                >
                  <img
                    css={tw`absolute inset-0 h-full w-full object-cover`}
                    src={content.image}
                    alt={content.headline}
                  />
                </div>
              )}
              <article
                css={css`
                  ${tw`flex flex-col items-start`}
                  &:hover {
                    button > svg {
                      ${tw`lg:(transform translate-x-2)`}
                    }
                  }
                `}
              >
                <time
                  css={[
                    global`typography.subtitle`,
                    tw`mb-5 font-bold text-primary-500 text-opacity-47`,
                  ]}
                >
                  {format(content.date, 'dd MMMM yyyy')}
                </time>

                <Heading
                  headingType="h4"
                  content={content.headline}
                  style={tw`font-bold text-primary-500`}
                />
                <Text content={content.excerpt} style={tw`mt-6`} />
                {content.callToAction && (
                  <Button
                    style={css`
                      ${tw`mt-12 bg-transparent text-primary-500`}
                      span {
                        ${tw`ml-0`}
                      }
                      svg {
                        ${tw`text-primary-500 lg:(transition-transform duration-300 ease-in-out)`}
                      }
                    `}
                    type="primary"
                    size="sm"
                    label={content.callToAction.label}
                  />
                )}
              </article>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

ExternalNews.propTypes = {
  news: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default ExternalNews
