import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { format } from '../utils/date'
import Button from './button'
import Heading from './heading'
import Image from './image'

const FeaturedArticle = ({ date, headline, excerpt, callToAction, image }) => {
  return (
    <article css={[global`layout.grid`, tw`items-center py-20 md:py-32`]}>
      <div
        css={tw`flex flex-col items-start justify-center mt-6 col-span-4 md:col-span-8 lg:(col-span-5 mt-0)`}
      >
        <div css={[global`typography.subtitle`, tw`mb-4 text-base font-bold text-primary-300`]}>
          {date != null && format(date, 'dd MMMM yyyy')}
        </div>
        <Heading style={tw`font-bold text-primary-500`} content={headline} headingType="h3" />
        <Heading style={tw`mt-6 font-normal`} content={excerpt} headingType="h6" />
        {callToAction && (
          <Button
            style={tw`mt-12`}
            type="primary"
            size="base"
            theme="navy-cyan"
            label={callToAction.label}
            link={callToAction.link}
          />
        )}
      </div>
      <Image
        image={image}
        style={tw`row-start-1 col-span-4 md:col-span-8 lg:(ml-12 row-start-auto col-span-7) xl:col-span-11`}
      />
    </article>
  )
}

FeaturedArticle.propTypes = {
  date: PropTypes.string,
  headline: PropTypes.string,
  excerpt: PropTypes.string,
  callToAction: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
  image: PropTypes.object,
}

export default FeaturedArticle
