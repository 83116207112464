import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import tw, { css, theme } from 'twin.macro'
import { global } from '../styles/global'
import { remToPx } from '../styles/typography'
import { format } from '../utils/date'
import Button from './button'
import Heading from './heading'
import Link from './link'
import Text from './text'

const ArticleGrid = ({ articles, title, togglePress }) => {
  return (
    <div css={[global`layout.container`, tw`flex flex-col`]}>
      <Heading
        headingType="h1"
        content={title}
        style={[
          global`typography.fluid`(
            remToPx(theme`fontSize.mobile-5xl`),
            remToPx(theme`fontSize.5xl`)
          ),
          tw`text-primary-500 text-opacity-40 lg:(leading-h2 tracking-h2)`,
        ]}
      />
      {togglePress ? (
        <Fragment>
          {articles && (
            <div
              css={tw`grid grid-cols-1 gap-y-14 first-of-type:mt-12 lg:(grid-cols-3 mt-12 gap-x-12 gap-y-24)`}
            >
              {articles
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((article) => (
                  <Link key={article.headline} to={article.callToAction.link}>
                    <article
                      css={css`
                        ${tw`flex flex-col items-start`}
                        &:hover {
                          button > svg {
                            ${tw`lg:(transform translate-x-2)`}
                          }
                        }
                      `}
                    >
                      <time
                        css={[
                          global`typography.subtitle`,
                          tw`mb-5 font-bold text-primary-500 text-opacity-47`,
                        ]}
                      >
                        {article.date != null && format(article.date, 'dd MMMM yyyy')}
                      </time>

                      <Heading
                        headingType="h4"
                        content={article.headline}
                        style={tw`font-bold text-primary-500`}
                      />
                      <Text content={article.excerpt} style={tw`mt-6`} />
                      {article.callToAction && (
                        <Button
                          style={css`
                            ${tw`mt-12 bg-transparent text-primary-500`}
                            span {
                              ${tw`ml-0`}
                            }
                            svg {
                              ${tw`text-primary-500 lg:(transition-transform duration-300 ease-in-out)`}
                            }
                          `}
                          type="primary"
                          size="sm"
                          label={article.callToAction.label}
                        />
                      )}
                    </article>
                  </Link>
                ))}
            </div>
          )}
        </Fragment>
      ) : (
        <p style={tw`mt-6 ml-2`}>No Press Release yet</p>
      )}
    </div>
  )
}

ArticleGrid.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default ArticleGrid
