import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import tw, { css, theme } from 'twin.macro'
import ArticleGrid from '../../components/article-grid'
import Banner from '../../components/banner'
import ExternalNews from '../../components/external-news'
import FeaturedArticle from '../../components/featured-article'
import Hero from '../../components/hero'
import Layout from '../../components/layout'
import SubNav from '../../components/sub-nav'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'

const NewsPage = ({ data: { news }, location }) => {
  const page = useMemo(() => flatten(news || {}, ['hero', 'featured', 'banner']), ['media'])
  const [activeLink, setActiveLink] = useState('#featured')
  const anchorLinks = useMemo(
    () => [
      {
        label: get(page, 'featuredLabel'),
        link: '#featured',
        active: activeLink === '#featured',
      },
      {
        label: get(page, 'pressReleasesLabel'),
        link: '#press',
        active: activeLink === '#press',
      },
      {
        label: get(page, 'externalNewsLabel'),
        link: '#external-news',
        active: activeLink === '#external-news',
      },
    ],
    [page, activeLink]
  )

  const articles = useMemo(
    () =>
      (get(page, 'pressReleases') || []).map((release) => ({
        date: release.date,
        headline: release.headline,
        excerpt: get(release, 'contentNode.childMarkdownRemark.excerpt'),
        callToAction: {
          label: get(page, 'pressReleasesButtonLabel'),
          link: `/news/${release.slug}`,
        },
      })),
    [page]
  )

  const externalNews = useMemo(
    () =>
      (get(page, 'externalNews') || []).map((content) => ({
        date: content.date,
        headline: content.title,
        excerpt: content.description,
        image: content.image,
        callToAction: {
          label: get(page, 'externalNewsButtonLabel'),
          link: content.link,
        },
      })),
    [page]
  )

  console.log(articles, "aqui tao os artigos")
  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} location={location}>
      <section id="hero" css={tw`bg-secondary-500`}>
        <Hero
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          style={css`
            h5 {
              ${tw`text-primary-500 text-opacity-77`}
            }
          `}
        />
      </section>
      <SubNav
        links={anchorLinks}
        style={css`
          ${tw`sticky z-10 bg-white bg-opacity-100 top-mobile-header lg:top-desktop-header`}
          li {
            ${tw`mx-8 first-of-type:ml-0`}
          }
          border-bottom: 1px solid #e5e5e5;
        `}
        selectBackground={theme`colors.white`}
        currentPath={activeLink}
      />
      <InView
        as="section"
        id="featured"
        rootMargin="-128px 0% -80% 0%"
        css={tw`bg-secondary-500 bg-opacity-5`}
        onChange={(view) => {
          if (view) setActiveLink('#featured')
        }}
      >
        <div css={global`layout.container`}>
          <FeaturedArticle
            date={get(page, 'featured.date')}
            headline={get(page, 'featured.headline')}
            excerpt={get(page, 'featured.contentNode.childMarkdownRemark.excerpt')}
            callToAction={{
              label: get(page, 'featuredButtonLabel'),
              link: `/news/${get(page, 'featured.slug')}`,
            }}
            image={get(page, 'featured.image')}
          />
        </div>
      </InView>
      <InView
        as="section"
        id="press"
        css={tw`pt-20 lg:pt-32`}
        rootMargin="-128px 0% -80% 0%"
        onChange={(view) => {
          if (view) setActiveLink('#press')
        }}
      >
        <ArticleGrid articles={articles} title={get(page, 'pressReleasesLabel')} togglePress={get(page, 'togglePressRelease')} />
      </InView>
      <InView
        as="section"
        id="external-news"
        css={tw`py-20 lg:py-32`}
        rootMargin="-128px 0% -80% 0%"
        onChange={(view) => {
          if (view) setActiveLink('#external-news')
        }}
      >
        <ExternalNews news={externalNews} title={get(page, 'externalNewsLabel')} />
      </InView>
      <section id="call-to-action" css={[global`layout.container`, tw`pb-32`]}>
        <Banner
          title={get(page, 'banner.title')}
          subtitle={get(page, 'banner.subtitleNode')}
          description={get(page, 'banner.descriptionNode')}
          callToAction={get(page, 'banner.callToAction')}
        />
      </section>
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.shape({
    news: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default NewsPage

export const query = graphql`
  query MediaQuery($locale: String!) {
    news: datoCmsKrNews(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      featuredLabel
      featuredButtonLabel
      featured {
        slug
        date
        headline
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          fluid(maxWidth: 1024, imgixParams: { fm: "jpg", auto: "compress", q: 75 }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      togglePressRelease
      pressReleasesLabel
      pressReleasesButtonLabel
      pressReleases {
        slug
        date
        headline
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      externalNewsLabel
      externalNewsButtonLabel
      externalNews {
        title
        description
        date
        image
        link
      }
      banner {
        title
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
    }
  }
`
